import React, { useEffect, useState } from 'react';
import { getProductName, LNTransferDetailsGenerator } from '../../../utils/LNTransferDetailsGenerator';
import { getQueryParamValue } from '../../../utils/general';
import { getEditParams } from '../../../api/backend';
import RosterGrid from './rosterGrid';

// Styles
import styles from './info.module.css'

interface LNDetailsProps {
  productType: string;
  fullWizardData: any;
  displayRoster: boolean;
  isPrintView?: boolean;
  checkMappedCards?: () => string;
}

const LNDetails: React.FC<LNDetailsProps> = ({ productType, fullWizardData, displayRoster, isPrintView = false, checkMappedCards }) => {
  const artIdParam = getQueryParamValue("artId");
  const [editParams, setEditParams] = useState<any>(undefined);
  const lnTransferDetails: { name: string, detail: string }[] = LNTransferDetailsGenerator[productType].GetDetails(fullWizardData, editParams) ?? [];
  const rosterData = Object.assign({}, editParams, fullWizardData)
  const columns = checkMappedCards ? checkMappedCards() : styles.twoColumns;

  useEffect(() => {
    (async () => {
      try {
        if (productType !== 'Embroidered' && artIdParam) {
          const params = await getEditParams(artIdParam);
          setEditParams(params);
        }
      } catch (error) {
        console.error("Error fetching edit params or transfer details", error);
      }
    })();
  }, []);

  const productName = getProductName(productType);
  const rowStyle = isPrintView
    ? "flex flex-row flex-wrap w-full px-0 py-[0.25em] border-b-[rgb(218,218,218)] border-b border-solid grid grid-cols-[25%_75%] gap-4"
    : styles.lnRow;

  return (
    <>
      <div className={[styles.card, columns, styles.lnCard, isPrintView && "text-sm"].join(' ')}>
        <h3 className={`uppercase text-[#F36E29] font-bold ${!isPrintView ? 'pt-[1.5em] pb-0 px-[1.5em]' : 'mb-4'}`}>Design Details</h3>
        <div className={`${styles.cardBody} ${isPrintView ? "mr-2" : ""}`}>
          {productName && !isPrintView &&
            <div className={rowStyle}>
              <span className={[styles.value, "pb-2 text-lg text-center w-full"].join(' ')}>{productName}</span>
            </div>
          }
          {lnTransferDetails?.map(lnTransferDetail => {
            const enumItems = /[:<br>]/.test(lnTransferDetail.detail);
            const detailLines = enumItems ? lnTransferDetail.detail.split(/<br\s*\/?>/) : [lnTransferDetail.detail];

            return (
              <div className={rowStyle} key={lnTransferDetail.name}>
                <span className={styles.name}>{lnTransferDetail.name}:</span>
                {enumItems ? (
                  <div className={styles.treeWrapper}>
                    {detailLines.map((line, index) => {
                      const [title, value] = line.split(': ');
                      const colorMatch = line.match(/background-color:\s*([^;"]+);/);
                      const bgImageMatch = line.match(/background-image:\s*url\(['"]?([^'")]+)['"]?\);/);
                      const color = colorMatch && colorMatch[1] !== '#undefined' ? colorMatch[1] : null;
                      const modifiedDetail = value?.match(/<span class="colorName">(.*?)<\/span>/);
                      const displayValue = modifiedDetail ? modifiedDetail[1] : value;
                      const hideItem = title?.trim() === '' && !displayValue;
                      const displayTitle = !color && !bgImageMatch ? `- ${title}${displayValue ? ':' : ''}` : '';

                      return (
                        <div key={index} className={styles.detailItem}>
                          {!hideItem && (
                            <span
                              className={styles.treeKey}
                              dangerouslySetInnerHTML={{ __html: displayTitle }}
                            ></span>
                          )}
                          <span
                            className={[styles.value, modifiedDetail ? styles.colorText : ''].join(' ')}
                            dangerouslySetInnerHTML={{ __html: displayValue }}
                          ></span>
                          {(color || bgImageMatch) && (
                            <div
                              className={styles.colorPreview}
                              style={{
                                backgroundColor: color || 'transparent',
                                backgroundImage: bgImageMatch ? `url(${bgImageMatch[1]})` : 'none',
                                width: '20px',
                                height: '20px',
                                display: 'inline-block',
                                marginLeft: '8px',
                                border: '1px solid #000',
                                marginTop: '5px',
                                backgroundSize: '100%',
                              }}
                              title={
                                color
                                  ? `Color preview: ${color}`
                                  : bgImageMatch
                                    ? `Image preview from: ${bgImageMatch[1]}`
                                    : 'Image preview from background image'
                              }
                            ></div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <span
                    className={styles.value}
                    dangerouslySetInnerHTML={{ __html: lnTransferDetail.detail }}
                  ></span>
                )}
              </div>
            );
          })}
        </div>
      </div>
      {/* Roster Grid */}
      {
        displayRoster && editParams &&
        <div className={[styles.card, columns, styles.bgWhite].join(' ')}>
          <RosterGrid wizardData={rosterData} />
        </div>
      }
    </>
  );
};

export default LNDetails;