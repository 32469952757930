import Info from "./comps/info";
import { generateDownloadOriginalArtworkURL, getColor } from "../../utils/general";
import FileUploadComponent from "./comps/fileUpload";
import formattedDate from "../../utils/formatted-date";
import { getBackendAPIDomain } from "../../config";

import ArtInformationAndQRCode from "./comps/artInformationAndQRCode";
import ImageSelector from "./comps/imageSelector";

import PrintTemplate from "./print-template";

import UserWorkFlow from "./comps/userWorkflow";
import { useSnapshot } from "valtio";

import appModel from "../../models/app-model";
import { useEffect, useState } from "react";
import { ArtworkType, ArtworkItemType, WizardDocConversation, DesignDoc } from "../../types/api-types";

import 'react-tabs/style/react-tabs.css';
import { IProdOrderLineItem } from "../../types/iprod-order";
import { InternalArtStates, toArtState, ArtStatesDescriptions, ArtworkFolder } from "../../types/art-status";
import { AppPages } from "../../app-pages";
import { archiveDesign, deleteDesign, getPriceBreaks, updateDesignDetails } from "../../models/app-actions";
import { ArtDetailsLoading } from "./loading";
import { downloadProductionFile, getFileExtension } from "../../utils/download-file";
import { DEFAULT_IMAGE } from "../../config";
import CustomModal from "../../components/customMessageModal/customMsgModal";
import { getBusinessCentralURLParams } from "../../utils/general";
import ArtDetailsModal from "./comps/artDetailsModal";
import ImagePreview from "./comps/imagePreivew";

// Styles
import styles from './art-details.module.css'; // index.tsx
import artStyles from '../art-dashboard/art.module.css';

function ViewMoreIcon() {
  return (
    <svg fill="none" viewBox="0 0 24 24"
      height="24px"
      width="24px" strokeWidth={1.5} stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
    </svg>
  )
}

const flex = "flex-wrap gap-6 px-4 sm:px-6 lg:px-8";

type MainBarProps = {
  adminId: string | null;
  page: AppPages;
  orderNo: string | null;
  lineNo: string | null;
  archived?: boolean;
  itemId: string;
};

const MainBar: React.FC<MainBarProps> = ({
  adminId,
  page,
  orderNo,
  lineNo,
  archived = false,
  itemId,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openArchiveModal, setOpenArchiveModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const handleBack = () => {
    window.history.back();
  };

  const handlePrint = () => {
    window.print();
  };

  const handleArchiveClick = () => {
    setOpenArchiveModal(true);
  }

  const handleDeleteClick = () => {
    setOpenDeleteModal(true);
  };

  const updateArchivedState = async (itemId: string) => {
    setIsLoading(true);
    try {
      const updateItemState = await archiveDesign(itemId);
      console.log(updateItemState);

    } catch (err: any) {
      console.log(err);
    } finally {
      setIsLoading(false);
      await updateDesignDetails();
    }
  }

  const deleteDesignItem = async (itemId: string) => {
    setIsLoading(true);
    try {
      await deleteDesign(itemId);
      console.log(`Design with ID ${itemId} deleted.`);
      handleBack();
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const businessCentralParams = getBusinessCentralURLParams();
  const currentUrl = new URL(window.location.href)
  const isDirectLink = (currentUrl.searchParams.get("directLink") ?? "").toLowerCase() == "true"
  const isProduction = businessCentralParams["SourceType"] == "Production";

  return (
    <div className={[flex, styles.mainBar].join(' ')}>
      {page === AppPages.DetailsArtist &&
        <h3 className="text-base font-light leading-6 text-white uppercase">
          ART & CUSTOMER SERVICE MODE
        </h3>}
      {!adminId && !isDirectLink &&
        <button
          onClick={handleBack}
          className={`${artStyles.orangeBtn} flex flex-row gap-1 text-white hover:bg-blue-500`}
        >
          Go Back
        </button>
      }
      {page === AppPages.DetailsProduction &&
        <h3 className={["text-base font-light leading-6 text-white uppercase", styles.qrCodeContainer].join(' ')}>
          <ArtInformationAndQRCode
            LineNo={lineNo}
            DocNo={orderNo}
            isProductionMode={page == AppPages.DetailsProduction}
          />
        </h3>
      }
      <div className={styles.navBarButtonsWrapper}>
        {/* Archive/Unarchive Confirmation Modal */}
        <CustomModal
          isOpen={openArchiveModal}
          onClose={() => setOpenArchiveModal(false)}
          message={`Are you sure you want to ${archived ? "unarchive" : "archive"}?`}
          onConfirm={() => updateArchivedState(itemId)}
          processing={isLoading}
          confirmButtonText="Confirm"
          showCancelButton={false}
          isCustomerView={!adminId}
        />
        <CustomModal
          isOpen={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          message="Are you sure you want to permanently delete this design? This action cannot be undone."
          onConfirm={() => deleteDesignItem(itemId)}
          processing={isLoading}
          confirmButtonText="Delete"
          showCancelButton={false}
          isCustomerView={!adminId}
        />
        {!isProduction && (
          <button
            type="button"
            className={`${styles.archiveBtn} non-printable`}
            onClick={handleArchiveClick}
          >
            <div className={styles.archive}>
              {!archived ? (
                // archive
                <>
                  <svg className="h-8 w-8" viewBox="0 0 24 24" fill="none">
                    <path d="M2 12.0002C2 7.28611 2 4.92909 3.46447 3.46462C4.70529 2.2238 6.58687 2.03431 10 2.00537M22 12.0002C22 7.28611 22 4.92909 20.5355 3.46462C19.2947 2.2238 17.4131 2.03431 14 2.00537" stroke="#fff" strokeWidth={2} strokeLinecap="round" />
                    <path d="M10 22C7.19974 22 5.79961 22 4.73005 21.455C3.78924 20.9757 3.02433 20.2108 2.54497 19.27C2 18.2004 2 16.8003 2 14C2 11.1997 2 9.79961 2.54497 8.73005C3.02433 7.78924 3.78924 7.02433 4.73005 6.54497C5.79961 6 7.19974 6 10 6H14C16.8003 6 18.2004 6 19.27 6.54497C20.2108 7.02433 20.9757 7.78924 21.455 8.73005C22 9.79961 22 11.1997 22 14C22 16.8003 22 18.2004 21.455 19.27C20.9757 20.2108 20.2108 20.9757 19.27 21.455C18.2004 22 16.8003 22 14 22" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M12 11L12 17M12 17L14.5 14.5M12 17L9.5 14.5" stroke="#fff" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <span>ARCHIVE</span>
                </>
              ) : (
                // un-archive
                <>
                  <svg className="h-8 w-8" viewBox="0 0 24 24" fill="none">
                    <path d="M2 11.9997C2 7.28562 2 4.9286 3.46447 3.46413C4.70529 2.22331 6.58687 2.03382 10 2.00488M22 11.9997C22 7.28562 22 4.9286 20.5355 3.46413C19.2947 2.22331 17.4131 2.03382 14 2.00488" stroke="#fff" strokeWidth={2} strokeLinecap="round" />
                    <path d="M10 22C7.19974 22 5.79961 22 4.73005 21.455C3.78924 20.9757 3.02433 20.2108 2.54497 19.27C2 18.2004 2 16.8003 2 14C2 11.1997 2 9.79961 2.54497 8.73005C3.02433 7.78924 3.78924 7.02433 4.73005 6.54497C5.79961 6 7.19974 6 10 6H14C16.8003 6 18.2004 6 19.27 6.54497C20.2108 7.02433 20.9757 7.78924 21.455 8.73005C22 9.79961 22 11.1997 22 14C22 16.8003 22 18.2004 21.455 19.27C20.9757 20.2108 20.2108 20.9757 19.27 21.455C18.2004 22 16.8003 22 14 22" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M12 17L12 11M12 11L14.5 13.5M12 11L9.5 13.5" stroke="#fff" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <span>UNARCHIVE</span>
                </>
              )}
            </div>
          </button>
        )}
        {adminId && (
          <button
            type="button"
            className={`${styles.archive} non-printable`}
            onClick={handleDeleteClick}
          >
            <div className={[styles.archive, styles.dltBtn].join(' ')}>
              <svg className="h-8 w-8" viewBox="0 0 24 24" fill="none">
                <path d="M3 6L5 6L21 6" stroke="#fff" strokeWidth={2} strokeLinecap="round" />
                <path d="M6 6L6 19C6 20.1046 6.89543 21 8 21H16C17.1046 21 18 20.1046 18 19V6" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M10 11L10 17M14 11L14 17" stroke="#fff" strokeWidth={2} strokeLinecap="round" />
                <path d="M9 3H15M15 3C16.1046 3 17 3.89543 17 5H7C7 3.89543 7.89543 3 9 3H15Z" stroke="#fff" strokeWidth={2} strokeLinecap="round" />
              </svg>
              <span>DELETE</span>
            </div>
          </button>
        )}
        {/* {ucMaxButtons} */}
        {adminId &&
          <>
            <button
              className={`${artStyles.orangeBtn} ${styles.printBtn} non-printable`}
              onClick={handlePrint}>
              <svg
                width={"22px"}
                height={"22px"}
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round"
                  d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z" />
              </svg>
            </button>
          </>
        }
        {!adminId && page === AppPages.DetailsArtist && (
          <button
            type="button"
            className={`${styles.deleteBtn} non-printable`}
            onClick={handleDeleteClick}
          >
            <div className={styles.delete}>
              <svg className="h-8 w-8" viewBox="0 0 24 24" fill="none">
                <path d="M6 6L18 18M18 6L6 18" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span>DELETE</span>
            </div>
          </button>
        )}
      </div>
    </div>
  );
};

function PageSwitch(props: { If: AppPages[], children: React.ReactNode }) {
  const appSnap = useSnapshot(appModel);

  if (props.If.includes(appSnap.page)) {
    return props.children
  } else {
    return <></>
  }
}

function DesignDetailPage() {
  const appSnap = useSnapshot(appModel);
  const prodLineItems = appSnap.prodLineItems
  const currentPage = appSnap.page;
  const lineNo = appSnap.lineNo;
  const orderNo = appSnap.orderNo;
  const wizardDoc = appSnap.activeDesignDoc;
  const archived = appSnap.activeDesignDoc?.archived;
  let wizardDocData = wizardDoc?.wizardData;
  const wizardDocFullData = wizardDoc?.wizardData;
  const productType = wizardDocData.productType ?? wizardDoc?.wizardID;
  const wizardDocConversations = appSnap.activeConversation as WizardDocConversation[] || [];
  const adminId = appSnap.adminId;
  const customerId = appSnap.customerId;
  const [disabledButtonClass,] = useState<string>(`${styles.disabledBtn} bg-gray-300 text-gray-500 cursor-not-allowed`);
  const [productionArtwork, setProductionArtwork] = useState<ArtworkType | null>(null);
  const [productionArtworkPreview, setProductionArtworkPreview] = useState<ArtworkType | null>(null);
  const [originalArtwork, setOriginalArtwork] = useState<ArtworkType | null>(null);
  const isEasyView = wizardDoc?.wizardID === "EasyView";
  const isLNTransfer = wizardDoc?.docType === "LNTransfer";
  const isWizardDesign = wizardDoc?.docType === "WizardDesign";
  const fullWizardData = wizardDocData
  wizardDocData = Array.isArray(wizardDocData) ? wizardDocData[0] : wizardDocData;
  wizardDocData = isEasyView ? { ...wizardDocData, ...wizardDocData?.extendedData } : wizardDocData;
  const [activeImage, setActiveImage] = useState('originalArtwork' as ArtworkFolder);
  const docObject = wizardDoc;
  const statusColor = wizardDoc ? getColor(toArtState(wizardDoc.statusID)) : ''
  const activeStatus = toArtState(appSnap.activeDesignDoc?.statusID ?? InternalArtStates.OPEN)
  const activeStatusDescription = ArtStatesDescriptions[activeStatus].description;
  const [imageSrc, setImgSrc] = useState<string>('');
  const [sewFileImg, setSewFileImg] = useState<ArtworkItemType | null>(null);
  const shippingAgentName = appSnap.prodLineItems.filter(x => !!x.shippingAgentServiceCode)[0]?.shippingAgentName ?? "--";
  const shippingAgentServiceDescription = appSnap.prodLineItems.filter(x => !!x.shippingAgentServiceCode)[0]?.shippingAgentServiceDescription ?? "--";
  const originalDocID = wizardDoc?.originalDocID;
  const [priceBreaks, setPriceBreaks] = useState<{ FromQty: number, ToQty: number, ItemPrice: number }[]>([]);
  const [priceBreaksMultiple, setPriceBreaksMultiple] = useState<number>(0);
  const [openMoreDetailsModal, setOpenMoreDetailsModal] = useState<boolean>(false);
  const [openImagePreview, setOpenImagePreview] = useState<boolean>(false);

  const handleImageSelect = (imageSrc: string) => {
    setImgSrc(imageSrc);
  };

  const [dwsDesignData, setDwsDesignData] = useState<any>({}); // State to hold fetched design data

  useEffect(() => {
    const fetchDesignData = async () => {
      const response = await fetch(
        `${getBackendAPIDomain()}/stahls/13/artworkupload/mapjobdatadetails?mapName=cart`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(wizardDoc),
        }
      );
      const data = await response.json();
      setDwsDesignData(Object.keys(data).length > 0 ? data : null);
    };

    if (isWizardDesign)
      fetchDesignData();
  }, [isWizardDesign]);

  useEffect(() => {
    if (customerId) {
      setActiveImage('productionArtworkPreview');
      getLastSewFile(wizardDoc?.productionArtwork as ArtworkType);
    }
  }, [customerId]);

  useEffect(() => {
    if (customerId && (isEasyView || (isWizardDesign && productType != 'CustomProduct' && productType != 'CustomPatchesEmbroidered' && productType != 'CustomPatchesVintage'))) {
      getPriceBreaks(wizardDocData).then(breaks => {
        if (!!breaks?.Multiple || breaks?.PriceBreaks) {
          setPriceBreaks(breaks.PriceBreaks)
          setPriceBreaksMultiple(breaks.Multiple)
        }
      })
    }
  }, [customerId, productType, isWizardDesign]);

  useEffect(() => {
    if (currentPage === AppPages.DetailsProduction && activeImage != "productionArtwork") {
      setActiveImage("productionArtwork");
    }
  }, [currentPage]);

  if (wizardDoc?.productionArtworkPreview && wizardDoc?.productionArtworkPreview !== productionArtworkPreview) {
    setProductionArtworkPreview(wizardDoc.productionArtworkPreview as ArtworkType)
  }

  if (wizardDoc?.productionArtwork && wizardDoc?.productionArtwork !== productionArtwork) {
    setProductionArtwork(wizardDoc.productionArtwork as ArtworkType)
  }

  if (!originalArtwork && wizardDoc?.originalArtwork) {
    const updatedOriginalArtwork = wizardDoc.originalArtwork.map((item) => ({
      ...item,
      originalFileName: wizardDoc.wizardData.origFileName,
    }));
    setOriginalArtwork(updatedOriginalArtwork as ArtworkType);
  }

  const handleButtonClick = (imageType: ArtworkFolder) => {
    setActiveImage(imageType);
  };

  const getLastSewFile = (productionArtworkImages: ArtworkType) => {
    const sewFiles = productionArtworkImages && productionArtworkImages?.filter(file => file.fileRole === "SewFile");
    if (sewFiles && sewFiles.length > 0)
      setSewFileImg(sewFiles[sewFiles.length - 1])
  };

  const downloadSewFile = () => {
    if (!sewFileImg) {
      console.error('sewFileImg is null');
      return;
    }

    const { fileRole, userFileId } = sewFileImg;
    const extension = getFileExtension(userFileId ?? '');
    const imageUrl = generateDownloadOriginalArtworkURL(sewFileImg, DEFAULT_IMAGE);

    downloadProductionFile(imageUrl, fileRole, "productionArtwork", extension);
  }

  const onUploadFinished = (data: ArtworkItemType) => {
    switch (activeImage) {
      case 'originalArtwork':
        const originalArtworkData = originalArtwork ? [...originalArtwork, data] : [data];
        setOriginalArtwork(originalArtworkData);
        break;
      case 'productionArtwork':
        const productionArtworkData = productionArtwork ? [...productionArtwork, data] : [data];
        setProductionArtwork(productionArtworkData);
        break;
      case 'productionArtworkPreview':
        const productionArtworkPreviewData = productionArtworkPreview ? [...productionArtworkPreview, data] : [data];
        setProductionArtworkPreview(productionArtworkPreviewData);
        break;
      default:
        console.error('Unknown art type');
    }
    updateDesignDetails()
  };

  function switchArtType(folder: ArtworkFolder) {
    switch (folder) {
      case 'originalArtwork': return originalArtwork
      case 'productionArtwork': return productionArtwork
      case 'productionArtworkPreview': return productionArtworkPreview
    }
  }

  function ImageTabs() {
    return <div className={styles.buttonsContainer}>
      <button
        style={{
          cursor: "pointer",
          fontSize: customerId ? "100%" : "unset",
          height: customerId ? "unset" : "100%"
        }}
        type="button"
        onClick={() => handleButtonClick('originalArtwork')}
        className={activeImage === 'originalArtwork' ? styles.activeButton : ''}>
        Original Files
      </button>
      <button
        style={{
          cursor: "pointer",
          fontSize: customerId ? "100%" : "unset",
          height: customerId ? "unset" : "100%"
        }}
        type="button"
        onClick={() => handleButtonClick('productionArtworkPreview')}
        className={activeImage === 'productionArtworkPreview' ? styles.activeButton : ''}>
        Proof Files
      </button>

      <PageSwitch If={[AppPages.DetailsArtist, AppPages.DetailsProduction]}>
        <button
          type="button"
          style={{
            cursor: "pointer",
            fontSize: customerId ? "100%" : "unset",
            height: customerId ? "unset" : "100%"
          }}
          onClick={() => handleButtonClick('productionArtwork')}
          className={activeImage === 'productionArtwork' ? styles.activeButton : ''}>
          Production Artwork
        </button>
      </PageSwitch>
    </div>
  }

  if (!wizardDoc)
    return <ArtDetailsLoading />
  else
    return (
      <>
        <div className={styles.wrapper}>
          <MainBar
            adminId={adminId}
            page={currentPage}
            orderNo={orderNo}
            lineNo={lineNo}
            archived={archived}
            itemId={wizardDoc.id} />

          {/* TOP Image and Actions */}
          <div className={styles.container}>

            {/* LEFT TOP */}
            <div className={[styles.column, styles.dualImg].join(' ')}>

              {/* Conditionally render ImageTabs */}
              {<ImageTabs />}

              <div className={[styles.imageContainer, styles.loaded].join(' ')}>

                {/* Image Manager */}
                <div className={styles.tabsContainer}>
                  <ImageSelector
                    imageType={activeImage}
                    documentId={wizardDoc.id}
                    onImageSelect={handleImageSelect}
                    imageData={switchArtType(activeImage)}
                    className={styles.pArtworkImg}
                    disabledButtonClass={disabledButtonClass}
                    alt={activeImage}
                    isAdmin={!!adminId}
                    isEasyView={isEasyView}
                    docType={wizardDoc?.docType}
                  />
                </div>
              </div>

              <div className={styles.bottomImgWrapper}>
                {/* Upload Image */}
                <PageSwitch If={[AppPages.DetailsArtist]}>
                  <FileUploadComponent
                    id={wizardDoc.id}
                    productType={productType}
                    onUploadFinished={(data: ArtworkItemType) => onUploadFinished(data)}
                  />
                </PageSwitch>

                {/* Preview Image */}
                {imageSrc !== DEFAULT_IMAGE &&
                  <button
                    type="button"
                    className={`${artStyles.orangeBtn} flex flex-row gap-1 text-white`}
                    style={{ cursor: "pointer" }}
                    onClick={() => setOpenImagePreview(true)}>
                    Preview
                  </button>
                }
                <ImagePreview
                  isOpen={openImagePreview}
                  onClose={() => setOpenImagePreview(false)}
                  imageSrc={imageSrc} />
              </div>
            </div>

            {/* RIGHT TOP */}
            <div className={styles.column}>
              <PageSwitch If={[AppPages.DetailsProduction]}>
                <div className={styles.orderInfoContainer}>
                  <div className={styles.orderInfoRow}>
                    <span className={styles.oiTitle}>Work Order No.</span>
                    <span>{orderNo}</span>
                  </div>
                  <div className={styles.orderInfoRow}>
                    <span className={styles.oiTitle}>Shipment Date</span>
                    <span>{appSnap.prodLineItems[0]?.duedate}</span>
                  </div>
                  <div className={styles.orderInfoRow}>
                    <span className={styles.oiTitle}>Ship</span>
                    <span>{shippingAgentName}/{shippingAgentServiceDescription}</span>
                  </div>
                  {isLNTransfer ?
                    <>
                      <div className={styles.orderInfoRow}>
                        <span className={styles.oiTitle}>Quantity of kits</span>
                        <span>{appSnap.prodLineItems[0]?.quantity}</span>
                      </div>
                      {wizardDoc?.wizardData?.quantity !== undefined && (
                        <div className={styles.orderInfoRow}>
                          <span className={styles.oiTitle}>Number of items per kit</span>
                          <span>{wizardDoc.wizardData.quantity}</span>
                        </div>
                      )}
                    </>
                    :
                    <div className={styles.orderInfoRow}>
                      <span className={styles.oiTitle}>Quantity</span>
                      <span>{appSnap.prodLineItems[0]?.quantity}</span>
                    </div>
                  }
                  <div className={styles.orderInfoRow}>
                    <span className={styles.oiTitle}>Sales Order No.</span>
                    <span>{appSnap.prodLineItems[0]?.salesorderno_vel}</span>
                  </div>
                </div>
              </PageSwitch>

              <PageSwitch If={[AppPages.DetailsCustomer, AppPages.DetailsArtist]}>
                <div className={styles.communicationContainer}>
                  <UserWorkFlow
                    designId={wizardDoc.id}
                    designStatus={wizardDoc.statusID}
                    conversations={wizardDocConversations}
                    isAdminMode={!!adminId}
                    archived={!!wizardDoc.archived}
                    allowThreads={false}
                    designCustomerId={wizardDoc.customerID} />
                </div>
              </PageSwitch>
            </div>
          </div>

          {/* ART CENTER ( Middle Area ) */}
          <div className={styles.artCenterContainer}>
            <div className={styles.artCenter}>
              <span className={styles.title}>ART CENTER</span>
              <div className={styles.artStatusInfo}>
                <span>Status:</span>
                <div className={styles.info}>
                  <span className={styles.details}>{currentPage === AppPages.DetailsHistory ? "Approved" : activeStatusDescription}</span>
                  <span className={[styles.badge, (statusColor ? styles[statusColor] : "")].join(' ')}></span>
                  <div className={styles.info}>
                    {docObject?.updateDate &&
                      <span className={styles.details}>
                        Last Modified: {(formattedDate({ dateString: docObject?.updateDate, format: 'MM/DD/YYYY' }) ?? "--")}
                      </span>
                    }
                  </div>
                </div>
              </div>
              <div className={styles.artStatusInfo}>
                <span>Account #</span>
                <div className={styles.info}>
                  <span className={styles.details}>{wizardDoc.customerID}</span>
                </div>
              </div>
              <div className={styles.artStatusInfo}>
                <span>Design ID</span>
                <div className={styles.info}>
                  <span className={styles.details}>{wizardDoc.id}</span>
                </div>
              </div>
              <div className={styles.moreDetails}
                onClick={() => setOpenMoreDetailsModal(true)}>
                <ViewMoreIcon />
                <span> More Art Details</span>
              </div>
            </div>
            <div className={styles.artCenterBtns}>
              {(sewFileImg && !adminId) &&
                <button
                  type="button"
                  className={styles.orangeBtn}
                  style={{ cursor: "pointer" }}
                  onClick={() => downloadSewFile()}>
                  Download Sew File
                </button>
              }
            </div>
            {priceBreaks?.length > 0 &&
              <div className={styles.priceWrapper}>
                <span className={styles.title}>PRICE BREAKS*</span>
                <div className={styles.priceList}>
                  {priceBreaksMultiple && priceBreaksMultiple > 1 && <span className={styles.pPriceMultiple}>Quantity must be multiple of {new String(priceBreaksMultiple)}</span>}
                  {priceBreaks.map((item, index) => {
                    console.log(item)
                    return (<div key={`${item.FromQty}to${item.ToQty}`} className={styles.priceBreak}>
                      <p className={styles.pQty}>{index === priceBreaks.length - 1 ? item.FromQty + '+' : item.FromQty === item.ToQty ? `${item.ToQty}` : `${item.FromQty}-${item.ToQty}`}</p>
                      <p className={styles.pPrice}>{`$${item.ItemPrice?.toFixed(2)} ea`}</p>
                    </div>)
                  })}
                  <span className={styles.pPrice}><i>*Prices subject to change</i></span>
                </div>
              </div>
            }
          </div>

          {/* Details Columns */}
          <div>
            <Info
              isEasyView={isEasyView}
              isLNTransfer={isLNTransfer}
              isWizardDesign={isWizardDesign}
              originalDocId={originalDocID}
              productType={productType}
              fullWizardData={fullWizardData}
              {...(dwsDesignData && isWizardDesign ? dwsDesignData : wizardDocData)}
            />
          </div>
        </div >

        {/* Printable Work Order aka Traveler  */}
        <div className={styles.printable}>
          <PrintTemplate
            wizardDocData={wizardDocData}
            woQR={{ orderNo, lineNo }}
            productionData={prodLineItems as IProdOrderLineItem[]}
            productionArtwork={productionArtwork}
            productionArtworkPreview={productionArtworkPreview}
            originalArtwork={originalArtwork}
            wizardDocFullData={wizardDocFullData}
          />
        </div>

        {/* More Art Details - Modal  */}
        <ArtDetailsModal
          isOpen={openMoreDetailsModal}
          onClose={() => setOpenMoreDetailsModal(false)}
          document={wizardDoc as DesignDoc}
        />
      </>
    );
}

export default DesignDetailPage 