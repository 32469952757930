import React from 'react';

interface WizardData {
  txtName?: string[];
  txtNumber?: string[];
  txtQTY?: number[];
  hOrderType?: string;
  hCharacter?: string;
  qty?: string;
  sku?: string;
  szQTYTypeToEdit?: string;
  szQTYToEdit?: string;
  szChars?: string;
  uofm?: string;
  [key: string]: any;
}

interface RosterProps {
  wizardData: WizardData;
  isPrintView?: boolean;
}

interface RowData {
  name?: string;
  number?: string;
  label?: string;
  quantity: number;
}

const letters = [
  "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P",
  "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "&"
];
const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
const greekLetters = [
  "ALPHA", "BETA", "GAMMA", "DELTA", "EPSILON", "ZETA", "ETA", "THETA",
  "IOTA", "KAPPA", "LAMBDA", "MU", "NU", "XI", "OMICRON", "PI", "RHO",
  "SIGMA", "TAU", "UPSILON", "PHI", "CHI", "PSI", "OMEGA"
];

type MappingResult = {
  array: string[] | number[];
  type: string | null;
};

function validateMappingLength(mappingString: string): MappingResult {
  const lettersLength = 27; // Length of `letters`
  const numbersLength = 10; // Length of `numbers`
  const greekLettersLength = 24; // Length of `greekLetters`
  const mappingArray = mappingString.split(",").map(val => val.trim());
  const arrayLength = mappingArray.length;

  if (arrayLength === lettersLength) {
    return { array: letters, type: "Letters" };
  } else if (arrayLength === numbersLength) {
    return { array: numbers, type: "Numbers" };
  } else if (arrayLength === greekLettersLength) {
    return { array: greekLetters, type: "Greek Letters" };
  } else {
    return { array: [], type: null };
  }
}

function mapData(
  input: string,
  splitDelimiter: string = ",",
  title: string = "Pack Contents"
) {
  const values = input.split(splitDelimiter).map(val => (val ? Number(val) : undefined));
  const mappingBase = validateMappingLength(input);

  if (!mappingBase) {
    throw new Error("Error: Unable to determine the mapping base.");
  }

  while (values.length < mappingBase.array.length) values.push(undefined);

  const rows = values
    .map((value, index) => (value !== undefined ? { value, mapped: mappingBase.array[index] } : null))
    .filter((item): item is { value: number; mapped: string | number } => item !== null);

  const labelHeader = mappingBase.type || "";

  const mappedRows = rows.map(r => ({
    label: String(r.mapped),
    quantity: r.value,
  }));

  return {
    rows: mappedRows,
    headers: [labelHeader, "Quantity"],
    title,
  };
}

const processRosterData = (
  wizardData: WizardData
): { rows: RowData[]; headers: string[]; title: string } | null => {
  // Case 1 - sku and qty
  if (wizardData.qty && wizardData.sku && !wizardData.hOrderType) {
    const quantities = wizardData.qty.split(';').map((q) => parseInt(q, 10));
    const skus = wizardData.sku.split(';');
    const lastCharacters = skus.map((sku) => sku.slice(-1));

    // Check if they are letters or numbers
    const areLetters = lastCharacters.every((char) => /^[A-Za-z]$/.test(char));
    const areNumbers = lastCharacters.every((char) => /^\d$/.test(char));

    const labelHeader = areLetters
      ? 'Letters'
      : 'Numbers'

    if (areLetters || areNumbers) {
      const mappedRows = skus.map((sku, index) => ({
        label: sku.slice(-1),
        quantity: quantities[index] || 0,
      }));

      return {
        rows: mappedRows,
        headers: [labelHeader, 'Quantity'],
        title: 'Pack Contents',
      };
    }
  }

  // Case 2 - hOrderType, hCharacter and qty
  if (wizardData?.hOrderType && wizardData?.hOrderType != "greek"
    && wizardData?.hCharacter && wizardData?.qty) {
    const characters = wizardData.hCharacter.split(';');
    const quantities = wizardData.qty.split(';').map((q) => parseInt(q, 10));

    const filteredRows = characters
      .map((character, index) => ({
        label: character.trim(),
        quantity: quantities[index] || 0,
      }))
      .filter((row) => row.label.length === 1);

    return {
      rows: filteredRows,
      headers: [wizardData.hOrderType, 'Quantity'],
      title: 'Pack Contents',
    };
  }

  // case 3 - Greek
  if (wizardData?.hOrderType && wizardData?.hOrderType == "greek") {
    const greekLettersMap: Record<string, string> = {};

    for (let index = 0; index < greekLetters.length; index++) {
      greekLettersMap[`${19 + index}`] = greekLetters[index]
    }

    const characters = wizardData.hCharacter?.split(';').filter(Boolean) ?? [];
    const quantities = wizardData.qty?.split(';').map(q => parseInt(q, 10));
    const greekRows = characters?.map((character, index) => ({
      name: greekLettersMap[character.trim()],
      quantity: quantities?.[index] || 0,
    }));

    return {
      rows: greekRows,
      headers: ["Greek Letters", "Quantity"],
      title: "Pack Contents",
    };
  }

  // Case 4 - szQTYToEdit, szQTYTypeToEdit (PreCut)
  if (wizardData?.szQTYToEdit && wizardData.szQTYTypeToEdit?.toLowerCase() !== "kit") {
    const { szQTYToEdit } = wizardData;
    return mapData(szQTYToEdit);
  }

  // Case 5 - szChars (SimStich)
  if (wizardData?.szChars && ["Piece"].includes(wizardData?.uofm ?? wizardData?.UOFM)) {
    const { szChars } = wizardData;
    return mapData(szChars);
  }

  // Case 6 - txtName, txtNumber, txtQTY
  if ((wizardData?.txtName || wizardData?.txtNumber) && wizardData?.txtQTY) {
    const { txtName, txtNumber, txtQTY } = wizardData;
    const rows: RowData[] = [];

    if (txtQTY) {
      for (let i = 0; i < txtQTY.length; i++) {
        rows.push({
          name: txtName?.[i],
          number: txtNumber?.[i],
          quantity: txtQTY[i],
        });
      }
    }

    const headers = [
      txtName ? 'Name' : null,
      txtNumber ? 'Number' : null,
      'Quantity',
    ].filter(Boolean) as string[];

    return { rows, headers, title: 'Name and/or Number List' };
  }

  return null;
};

const RosterGrid: React.FC<RosterProps> = ({ wizardData, isPrintView = false }) => {
  const rosterData = processRosterData(wizardData);
  if (!rosterData) {
    return null;
  }

  const { rows, headers, title } = rosterData;

  return (
    <>
      <div className={`flex flex-col ${isPrintView && "text-sm"}`}>
        <h3 className={`uppercase text-[#F36E29] font-bold ${!isPrintView ? 'pt-[1.5em] pb-0 px-[1.5em]' : ''}`}>{title}</h3>
        <div className={`bg-white w-full pt-6 ${!isPrintView ? 'px-5' : ''}`}>
          <table className="table-auto w-full">
            <thead>
              <tr className="bg-[#f2f2f2] border-b-[rgb(218,218,218)] border-b border-solid rounded-xl">
                {headers.map((header, index) => (
                  <th
                    key={index}
                    className={`px-4 ${!isPrintView ? 'py-2' : 'py-1'} text-left text-[black] font-medium capitalize`}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr
                  key={index}
                  className="border-b-[rgb(218,218,218)] border-b border-solid px-0 py-[0.5em] font-semibold text-[gray]"
                >
                  {row.name && <td className={`px-4 ${!isPrintView ? 'py-2' : 'py-1'}`}>{row.name}</td>}
                  {row.number && <td className={`px-4 ${!isPrintView ? 'py-2' : 'py-1'}`}>{row.number}</td>}
                  {row.label && <td className={`px-4 ${!isPrintView ? 'py-2' : 'py-1'}`}>{row.label}</td>}
                  <td className={`px-4 ${!isPrintView ? 'py-2' : 'py-1'}`}>{row.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default RosterGrid;