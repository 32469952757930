import { useState, ChangeEvent, useRef, useEffect } from 'react'
import Spinner from './spinner'
import { ArtworkFolder } from '../../../types/art-status'
import { uploadFile } from '../../../models/app-actions'
import { ArtworkItemType } from '../../../types/api-types'
import FileUploadModal from './fileUploadModal'

// Sytles
import styles from './fileUpload.module.css'

export enum FileRole {
  DefaultFile = "DefaultFile",
  FlipFile = "FlipFile",
  SewFile = "SewFile",
}

interface FileUploadComponentProps {
  id: string
  productType?: string
  onUploadFinished?: (uploadArtResult: ArtworkItemType) => void
}

const FileUploadComponent = (props: FileUploadComponentProps) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [statusMessage, setStatusMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [fileRole, setFileRole] = useState<FileRole>(FileRole.DefaultFile)
  const [artType, setArtType] = useState<ArtworkFolder>("productionArtworkPreview")
  const [error, setError] = useState<any>(null)
  const buttonClasses = `${styles.orangeBtn} flex flex-row gap-1 text-white hover:bg-blue-500`;

  useEffect(() => {
    if (selectedFile) {
      handleUpload()
      setIsModalOpen(false)
    }
  }, [selectedFile])

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const maxFileSize = fileRole === FileRole.SewFile 
      ? 20 * 1024 * 1024   // 20 MB
      : 50 * 1024 * 1024;  // 50 MB
  
    const file = event.target.files && event.target.files[0];
  
    if (file && file.size > maxFileSize) {
      const limit = fileRole === FileRole.SewFile ? '20MB' : '50MB';
      setError(`File size is too large. Please select a file less than ${limit}.`);
      setSelectedFile(null);
      // Reset the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } else if (file && file.size <= 0) {
      setError(`File size cannot be 0.`);
      setSelectedFile(null);
      // Reset the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } else {
      setError(null);
      setSelectedFile(file || null);
    }
  };

  const triggerFileInput = () => {
    setIsModalOpen(true)
  };

  const resetForm = () => {
    setSelectedFile(null);
    setFileRole(FileRole.DefaultFile);
    setArtType("productionArtworkPreview");

    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  };

  const handleUpload = async () => {
    setIsLoading(true);

    if (!selectedFile) {
      setStatusMessage('Please select a file to upload.')
      setIsLoading(false);
      return;
    }
    try {
      //Action to Upload FIle
      const fileId: ArtworkItemType = await uploadFile(props.id, selectedFile, artType, fileRole)

      props.onUploadFinished && props.onUploadFinished(fileId)

      setStatusMessage('File uploaded successfully.')
      setTimeout(() => {
        setStatusMessage('')
      }, 3000)

    } catch (error) {
      console.error('Error uploading file:', error)
      setStatusMessage('Error uploading file.')
      setError(error)
    }
    finally {
      setIsLoading(false)
      resetForm()
    }
  };

  const handleFileSelection = (role: FileRole, artType: ArtworkFolder) => {
    setFileRole(role);
    setArtType(artType);
    fileInputRef.current?.click();
  };

  return <>
    <div className={styles.fileUploadContainer}>
      <input
        style={{ display: 'none' }}
        ref={fileInputRef}
        type="file"
        onChange={handleFileChange}
      />
      <div className={styles.row}>
        <button onClick={triggerFileInput} type="button" className={buttonClasses}>
          Upload File
        </button>
        <Spinner spinning={isLoading}></Spinner>
      </div>
      <span className={[styles.msgFileUpload, error ? styles.errorMsg : ''].join(' ')}>{statusMessage}</span>
    </div>
    <FileUploadModal
      isOpen={isModalOpen}
      productType={props.productType}
      onClose={() => setIsModalOpen(false)}
      onFileSelected={handleFileSelection}
      uploadError={error}
    />
  </>

}

export default FileUploadComponent